<template>
  <div class="home">
    <Navbar></Navbar>

    <!-- INTRODUCE MYSELF SECTION -->
    <section class="section-hero">
      <div class="container mt-3 mt-lg-5">
        <div class="row justify-content-center mb-3">
          <div class="col-md-8 text-center mb-3">
            <img src="@/assets/img/gian-indigo.png"
                 class="img-fluid border-radius-50 shadow-md animate-sm animation-pulse"
                 width="100px" height="100px"
                 alt="Giancarlos Gza">
          </div>
          <div class="col-md-8 text-center">
            <vue-aos animation-class="animate__animated animate__tada">
              <div class="text-subhero fw-500">Hi! I'm Gian, 22 👋</div>
            </vue-aos>
          </div>
        </div>
        <div class="row justify-content-center mb-3">
          <div class="col-md-8 text-center">
            <h1 class="text-hero fw-500">
              <span class="text-brand-underline">Building</span> digital products, tools & more.
            </h1>
            <p class="paragraph">
              My name is Giancarlos Garza. I'm Computer Systems Engineer from Monterrey, MX.
              Specialized in UI/UX design and front end web development.
            </p>
            <div>
              <input type="checkbox" class="btn-check" id="btn-check" autocomplete="off" v-model="darkMode">
              <label class="btn btn-primary-outline btn-micint-rotate btn-round ripple ripple-primary px-3 m-0"
                     for="btn-check">
                <i class="material-icons-outlined me-2" :class="[ darkMode ? 'text-warning' : 'text-muted']">
                  {{ darkMode ? 'brightness_5' : 'dark_mode' }}</i>
                Turn {{ darkMode ? 'on' : 'off' }} the lights
              </label>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- PROJECTS SECTION -->
    <section class="section-hero sc-sm" id="projects">
      <div class="container">
        <div class="row mb-3">
          <div class="col-md-12">
            <typical
                class="typicalWrapper overline text-primary mb-2"
                :steps="['Think.', 1000, 'Think. Design.', 1000, 'Think. Solve.', 1000]"
                :loop="Infinity"
                :wrapper="'div'"
            ></typical>
            <h3 class="fw-500">Projects.</h3>
          </div>
        </div>
        <MenuffyCTA></MenuffyCTA>
        <ColorffyCTA></ColorffyCTA>
      </div>
    </section>

    <!-- BLOG SECTION -->
    <section class="section-hero sc-sm">
      <div class="container">
        <div class="row mb-3">
          <div class="overline text-primary mb-2">Blog</div>
          <h3 class="fw-500">Latest posts.</h3>
        </div>
        <PostsList :posts="posts" :col="6"></PostsList>
        <div class="row mb-3">
          <div class="col-md-12 text-center">
            <router-link to="/posts" class="btn btn-primary-light btn-round ripple ripple-primary py-3 px-5">
              <i class="material-icons-outlined me-3">auto_stories</i>
              See more posts
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/includes/Navbar";
import Footer from "@/components/includes/Footer";
import Typical from "vue-typical"
import VueAos from 'vue-aos'
import PostsList from "@/components/posts/PostsList";
import firebase from 'firebase/app'
import 'firebase/firestore'
import MenuffyCTA from "@/components/cta/MenuffyCTA";
import ColorffyCTA from "@/components/cta/ColorffyCTA";

export default {
  name: 'Home',
  metaInfo: {
    title: 'Home',
  },
  components: {
    ColorffyCTA,
    MenuffyCTA,
    PostsList,
    Navbar, Footer, Typical, VueAos
  },
  data() {
    return {
      posts: [],
      db: firebase.firestore(),
      darkModeIsActive: 'off'
    }
  },
  mounted() {
    this.fetchPosts()
  },
  methods: {
    toggleText() {
      this.darkModeIsActive = !this.darkModeIsActive;
    },
    async fetchPosts() {

      let postsSnap = this.db
          .collection('posts')

      postsSnap = await postsSnap
          .orderBy('createdAt', "desc")
          .limit(2)
          .get()

      this.posts = postsSnap.docs.map(doc => {
        return {id: doc.id, ...doc.data()}
      })
    },
  },
  computed: {
    darkMode: {
      get() {
        return this.$store.getters.darkMode
      },
      set(newValue) {
        this.$store.commit("SET_DARK_MODE", newValue)
      }
    }
  }
}
</script>
