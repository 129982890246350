<template>
  <div>
    <Navbar></Navbar>
    <div class="container mt-3 mt-lg-5">

      <!-- TITLE-->
      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <router-link to="/admin/posts" class="btn btn-link btn-micint-xr ripple ripple-primary m-0">
            <i class="material-icons-outlined">arrow_left</i>
            Go back
          </router-link>
        </div>
        <div class="col-md-12 text-center">
          <h4 class="fw-bold">Edit <span class="text-brand-underline">post</span></h4>
        </div>
      </div>

      <!-- FORM -->
      <div class="row justify-content-center mb-3">
        <div class="col-md-8 mb-3">
          <div v-if="error" class="alert alert-danger mb-3">{{ error }}</div>
          <div class="card card-outline">
            <div class="card-body">
              <form action="#" @submit.prevent="onUpdate" class="row">

                <div class="col-md-12 mb-3">
                  <InputFile id="input-image" label="Post header image" v-model="headerImage"></InputFile>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="input-title" class="mb-2">Post title</label>
                  <input v-model="post.title" type="text" class="form-control form-big-height" id="input-title"
                         placeholder="Ex. VueJs Tutorial" required autocomplete="off"
                         autofocus>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="input-description" class="mb-2">Post small description</label>
                  <textarea v-model="post.description" type="text" class="form-control form-big-height"
                            id="input-description"
                            placeholder="Ex. VueJs blog using firebase for beginners" required autocomplete="off"
                            autofocus></textarea>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="input-body" class="mb-2">Post body</label>
                  <vue-editor v-model="post.body" id="input-body"></vue-editor>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="input-color-keywords" class="mb-2">Post keywords</label>
                  <v-select v-model="post.postKeywords" class="" id="input-color-keywords" multiple
                            :options="['5 min', 'Colorffy', 'Design', 'Firebase', 'Geek', 'HTML', 'Menuffy', 'SCSS', 'Tech', 'Tutorial', 'UI/UX', 'VueJs', 'Web']"/>
                </div>

                <div class="col-md-12 text-end mt-3">
                  <button class="btn btn-primary btn-xs-full-width ripple px-5">Edit post</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/includes/Navbar";
import Footer from "@/components/includes/Footer";
import firebase from 'firebase/app'
import 'firebase/firestore'
import {VueEditor} from "vue2-editor";
import InputFile from "@/components/forms/InputFile";

export default {
  name: "EditPost",
  metaInfo: {
    title: 'Edit Post',
  },
  components: {Footer, Navbar, VueEditor, InputFile},
  data() {
    return {
      error: null,
      post: {},
      headerImage: null
    }
  },
  mounted() {
    this.getPost()
  },
  methods: {
    async getPost() {
      const db = firebase.firestore()
      const post = await db.collection('posts').doc(this.$route.params.postId).get()
      this.post = {id: post.id, ...post.data()}
      console.log(this.post.id)
    },

    onUpdate() {
      const db = firebase.firestore()

      if (this.headerImage) {
        const storageRef = firebase.storage().ref()
        const fileExtension = this.headerImage.name.split('.').pop()
        const uploadTask = storageRef
            .child(`posts/${this.post.id}/image.${fileExtension}`)
            .put(this.headerImage)

        uploadTask.on('state_changed', snapshot => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')

              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  console.log("Upload is paused")
                  break
                case firebase.storage.TaskState.RUNNING:
                  console.log("Upload is running")
              }
            },
            err => {
              this.error = err.message
            },
            () => {
              console.log(this.post.id)
              uploadTask.snapshot.ref.getDownloadURL()
                  .then(downloadURL => db
                      .collection('posts').doc(this.post.id)
                      .set({headerImage: downloadURL}, {merge: true}))
            })
      }

      const postUID = db.collection('posts').doc(this.post.id)
      postUID.set({
        title: this.post.title,
        description: this.post.description,
        body: this.post.body,
        postKeywords: this.post.postKeywords,
        createdAt: new Date(Date.now()).toLocaleString()
      }, {merge: true})

      this.$swal({
        customClass: {
          confirmButton: 'btn btn-primary btn-w-100',
        },
        buttonsStyling: false,

        showConfirmButton: true,
        icon: 'success',
        title: 'Post edited!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.replace("/admin/posts")
        }
      }).catch(err => {
        this.error = err.message
      })
    }
  }
}
</script>