import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import firebase from 'firebase/app'
import 'firebase/analytics'
import Hotjar from 'vue-hotjar'
import VueSweetalert2 from 'vue-sweetalert2'
import vSelect from 'vue-select'

import 'popper.js'
import 'bootstrap';

Vue.config.productionTip = false

Vue.component('v-select', vSelect)
Vue.use(VueMeta);
Vue.use(VueSweetalert2);
Vue.use(require('vue-moment'));

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

if (process.env.NODE_ENV === 'production') {
    firebase.analytics();
    Vue.use(Hotjar, {
        id: '2247116', // Hotjar Site ID
        isProduction: true,
    })
}

firebase.auth().onAuthStateChanged(user => store.dispatch("fetchUser", user)
    .then(async () =>
        new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount('#app')))