<template>
  <div id="app">
    <div class="router-view">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<style lang="scss">
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "./assets/sass/main.scss";
</style>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'App',
  metaInfo: {
    title: 'Gian Gza Website ',
    titleTemplate: '%s | Gian Gza Website',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Welcome to my blog! Here I will write about web design, UI/UX, tech reviews, tutorials & more!'
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'blog, vuejs, firebase, scss, tutorials, geek, html, web design, ui/ux, ui design'
      },
      // Twitter Card
      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:url', content: 'https://giangza.dev'},
      {name: 'twitter:title', content: 'GianGza - Website'},
      {
        name: 'twitter:description',
        content: 'Welcome to my blog! Here I will write about web design, UI/UX, tech reviews, tutorials & more!'
      },
      {
        vmid: 'twitter:image',
        name: 'twitter:image',
        content: `${window.location.protocol}//${window.location.host}` + require('./assets/seo.png')
      },
      // Facebook OpenGraph
      {property: 'og:type', content: 'website'},
      {property: 'og:url', content: 'https://giangza.dev'},
      {property: "og:title", content: "GianGza - Website"},
      {
        property: 'og:description',
        content: 'Welcome to my blog! Here I will write about web design, UI/UX, tech reviews, tutorials & more!'
      },
      {property: 'og:site_name', content: 'GianGza'},
      {
        vmid: 'og:image',
        property: 'og:image',
        content: `${window.location.protocol}//${window.location.host}` + require('./assets/seo.png')
      },
    ]
  },
  mounted() {
    // check for active theme
    const htmlElement = document.documentElement;
    const theme = localStorage.getItem("theme");
    if (theme === 'dark') {
      htmlElement.setAttribute('theme', 'dark')
      this.$store.commit("SET_DARK_MODE", true)
    } else {
      htmlElement.setAttribute('theme', 'light');
      this.$store.commit("SET_DARK_MODE", false)
    }
  },
  computed: {
    ...mapGetters(["darkMode"]),
  },
  watch: {
    darkMode: function () {
      // add/remove class to/from html tag
      const htmlElement = document.documentElement;
      if (this.darkMode) {
        localStorage.setItem("theme", 'dark');
        htmlElement.setAttribute('theme', 'dark');
      } else {
        localStorage.setItem("theme", 'light');
        htmlElement.setAttribute('theme', 'light');
      }
    }
  }
}
</script>