<template>
  <div class="row mb-3">
    <div :class="'mb-3 col-md-6 col-lg-'+ col" v-for="(post, index) in posts"
         v-bind:key="index + 'post-card'">
      <router-link :to="'/post/' + post.id">
        <div class="card card-post">
          <div class="card-body">
            <div class="row">
              <div class="col-4 col-md-12 align-self-center">
                <img v-if="post.headerImage" :src="post.headerImage" class="img-fluid post-img post-list-img mb-3"
                     :alt="post.title + ' image'">
              </div>
              <div class="col-8 col-md-12">
                <div class="card card-no-border">
                  <div class="card-body">
                    <div class="overline text-muted mb-3" style="font-size: 12px">{{ post.createdAt }}</div>
                    <h5 class="post-title fw-500 mb-3">{{ post.title }}</h5>
                    <p class="post-subtitle subtitle-1 d-none d-md-block">{{ post.description }}</p>
                    <p class="subtitle-2 mt-3">
                      <img src="@/assets/img/gian.jpg" class="img-fluid border-radius-50 me-1" width="20px"
                           height="20px"
                           alt="Giancarlos Gza">
                      Giancarlos Garza
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostsList',
  props: [
    'posts',
    'col',
  ],
}
</script>