import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'
import Home from '@/views/Home.vue'
import Login from "@/views/auth/Login";
import Profile from "@/views/Profile";
import PageNotFound from "@/views/error/404";
import PostsPanel from "@/views/admin/PostsPanel";
import NewPost from "@/views/admin/NewPost";
import EditPost from "@/views/admin/EditPost";
import Posts from "@/views/posts/Posts";
import Post from "@/views/posts/Post";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },

    {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound,
    },

    {
        path: '/user/profile',
        name: 'Profile',
        component: Profile,
        meta: {requireAuth: true}
    },

    //---------- POSTS ROUTES ----------//
    {
        path: '/posts',
        name: 'Posts',
        component: Posts,
    },

    {
        path: '/post/:postId',
        name: 'Post',
        component: Post,
    },

    //---------- AUTH ROUTES ----------//
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
    },

    //---------- ADMIN ROUTES ----------//
    {
        path: '/admin/posts',
        name: 'PostsPanel',
        component: PostsPanel,
        meta: {requireAuth: true}
    },
    {
        path: '/admin/posts/create',
        name: 'NewPost',
        component: NewPost,
        meta: {requireAuth: true}
    },
    {
        path: '/admin/posts/edit/:postId',
        name: 'EditPost',
        component: EditPost,
        meta: {requireAuth: true}
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

router.beforeEach(async (to, from, next) => {
    // const loggedIn = store.getters.user.loggedIn
    const requireAuth = to.matched.some(record => record.meta.requireAuth)
    const guestOnly = to.matched.some(record => record.meta.guestOnly)
    const firebaseUser = firebase.auth().currentUser

    if (requireAuth && !firebaseUser) next('login')
    else if (guestOnly && firebaseUser) next('profile')
    else next()
})

export default router
