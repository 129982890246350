<template>
  <div>
    <!-- MODAL DELETE CONFIRMATION -->
    <div class="modal fade" :id="id" tabindex="-1" :aria-labelledby="id" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-500" :id="id">{{ label }}</h5>
            <button type="button" class="btn-close img-logo" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body py-4">
            <div class="subtitle-1">Are you sure you want to delete</div>
            <div class="subtitle-1">"<span class="fw-500">{{ name }}</span>"?</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link ripple ripple-dark px-3" data-bs-dismiss="modal">Cancel
            </button>
            <button type="button" class="btn btn-danger ripple px-3" data-bs-dismiss="modal" @click="deleteItem">
              <i class="material-icons-outlined me-2">delete</i>
              {{ label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'label',
    'id',
    'value',
    'name',
  ],
  methods: {
    deleteItem() {
      this.$emit('deleteItem')
    }
  }
}
</script>