<template>
  <div class="row mb-5">
    <div class="col-md-12" v-for="(post, index) in posts"
         v-bind:key="index + 'post-card'">
      <router-link :to="'/post/' + post.id">
        <vue-aos animation-class="animate__animated animate__fadeInUp">
          <div class="card card-post">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-lg-8 text-start align-self-center">
                  <img v-if="post.headerImage" :src="post.headerImage" class="img-fluid post-img"
                       :alt="post.title + ' image'">
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="post-lastone-container">
                    <div>
                      <span class="badge badge-success px-3 mx-0 my-3">NEW POST</span>
                      <h4 class="post-title fw-500 mb-3">{{ post.title }}</h4>
                      <p class="subtitle-1 text-muted">{{ post.description }}</p>
                    </div>
                    <div>
                      <p class="subtitle-2 mt-3">
                        <img src="@/assets/img/gian.jpg" class="img-fluid border-radius-50 me-1" width="20px"
                             height="20px"
                             alt="Giancarlos Gza">
                        Giancarlos Garza,
                        <span class="text-muted">Front end developer</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-aos>
      </router-link>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import VueAos from 'vue-aos'

export default {
  name: "PostLastOne",
  data() {
    return {
      posts: [],
      db: firebase.firestore(),
    }
  },
  components: {
    VueAos
  },
  mounted() {
    this.fetchPost()
  },
  methods: {
    async fetchPost() {
      let postsSnap = this.db
          .collection('posts')
      postsSnap = await postsSnap
          .orderBy('createdAt', "desc")
          .limit(1)
          .get()
      this.posts = postsSnap.docs.map(doc => {
        return {id: doc.id, ...doc.data()}
      })
    },
  },

}
</script>