<template>
  <div>
    <Navbar></Navbar>
    <div class="container mt-3 mt-lg-5">

      <!-- TITLE-->
      <div class="row align-items-center mb-3">
        <div class="col-md-12">
          <div class="overline text-primary mb-2">Blog</div>
          <vue-aos animation-class="animate__animated animate__flipInX">
            <div>
              <h4 class="fw-500">Giancarlos Garza 👋</h4>
              <p class="paragraph text-muted">Web design, UI/UX, tech reviews, tutorials & more! </p>
            </div>
          </vue-aos>
        </div>
      </div>

      <PostLastOne></PostLastOne>

      <!-- SEARCH COLORS SHORTCUTS -->
      <div class="col-md-6 mb-3">
        <button class="btn btn-default ripple ripple-dark" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFilters"
                aria-expanded="false" aria-controls="collapseFilters">
          <i class="material-icons-outlined me-2">filter_list</i>
          Filters
        </button>
      </div>
      <div class="col-md-12">
        <div class="collapse" id="collapseFilters">
          <div class="card card-outline mb-3">
            <div class="card-body">
              <div class="scrolling-wrapper row flex-row flex-nowrap">
                <div class="col-3 col-md-2 col-lg-2 text-center">
                  <button class="btn btn-default btn-round ripple ripple-primary px-5" @click="changeKeyword('5 min')">
                    5 min
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-5"
                          @click="changeKeyword('Colorffy')">
                    Colorffy
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-5" @click="changeKeyword('Design')">
                    Design
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-5"
                          @click="changeKeyword('Firebase')">
                    Firebase
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-5" @click="changeKeyword('Geek')">
                    Geek
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-5" @click="changeKeyword('HTML')">
                    HTML
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-5"
                          @click="changeKeyword('Menuffy')">
                    Menuffy
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-5" @click="changeKeyword('SCSS')">
                    SCSS
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-5" @click="changeKeyword('Tech')">
                    Tech
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-5"
                          @click="changeKeyword('Tutorial')">
                    Tutorial
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-5" @click="changeKeyword('UI/UX')">
                    UI/UX
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-3" @click="changeKeyword('VueJs')">
                    VueJs
                  </button>
                  <button class="btn btn-default btn-round ripple ripple-primary px-5" @click="changeKeyword('Web')">
                    Web
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- POST SECTION -->
      <div v-if="!posts || (posts && posts.length < 1)" class="col-md-12 text-center my-5">
        <EmptyState title="There are no posts here!"
                    subtitle="Come back later or try with another keyword 😢">
        </EmptyState>
      </div>
      <PostsList :posts="posts" :col="4"></PostsList>

      <!-- PAGINATION SECTION -->
      <div v-if="!posts || (posts && posts.length > 11)" class="row mb-3">
        <div class="col-md-12 text-center mb-3">
          <button class="btn btn-primary btn-round ripple ripple-dark px-5" @click="loadMorePosts">Load more
            posts
            <i class="material-icons-outlined">expand_more</i>
          </button>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/includes/Navbar";
import Footer from "@/components/includes/Footer";
import firebase from 'firebase/app'
import 'firebase/firestore'
import VueAos from 'vue-aos'
import PostsList from "@/components/posts/PostsList";
import PostLastOne from "@/components/posts/PostLastOne";
import EmptyState from "@/components/empty-states/EmptyState";

export default {
  name: "Posts",
  metaInfo: {
    title: 'Blog',
  },
  components: {EmptyState, PostLastOne, PostsList, Footer, Navbar, VueAos},
  data() {
    return {
      posts: [],
      db: firebase.firestore(),
      keywordValue: '',
      lastPost: null,
      disabledButton: false
    }
  },
  mounted() {
    this.fetchPosts()
  },
  methods: {
    async fetchPosts() {

      let postsSnap = this.db
          .collection('posts')

      if (this.keywordValue) {
        postsSnap = postsSnap
            .where('postKeywords', 'array-contains', this.keywordValue)
      }

      postsSnap = await postsSnap
          .orderBy('createdAt', "desc")
          .limit(6)
          .get()

      this.lastPost = postsSnap.docs[postsSnap.docs.length - 1]

      this.posts = postsSnap.docs.map(doc => {
        return {id: doc.id, ...doc.data()}
      })
    },

    async loadMorePosts() {
      try {
        let postsSnap = this.db
            .collection('posts')

        if (this.keywordValue) {
          postsSnap = postsSnap
              .where('postKeywords', 'array-contains', this.keywordValue)
        }

        postsSnap = await postsSnap
            .orderBy('createdAt', "desc")
            .limit(6)
            .startAfter(this.lastPost)
            .get()

        this.lastPost = postsSnap.docs[postsSnap.docs.length - 1]

        postsSnap.forEach(doc => {
          this.posts.push({id: doc.id, ...doc.data()})
        })

      } catch (e) {
        console.log(e.message)
      }
    },

    changeKeyword(keyword) {
      this.keywordValue = keyword
    }
  },

  watch: {
    keywordValue: {
      handler(keywordValue) {
        if (keywordValue) {
          this.fetchPosts()
        }
      }
    },
  },

}
</script>