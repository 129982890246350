<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top" :class="{ 'shadow-sm': !showShadow }">
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img src="@/assets/img/logos/logo-form.png" width="50px" class="img-fluid" alt="Gian's logo img">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon img-logo"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav me-auto">
          <li class="nav-item d-none d-lg-block">
            <div>
              <input type="checkbox" class="btn-check" id="btn-check" autocomplete="off" v-model="darkMode">
              <label class="btn btn-link btn-micint-rotate ripple ripple-primary m-0" for="btn-check">
                <i class="material-icons-outlined" :class="[ darkMode ? 'text-warning' : 'text-muted']">
                  {{ darkMode ? 'brightness_5' : 'dark_mode' }}</i>
              </label>
            </div>
          </li>
          <li class="nav-item align-self-lg-center">
            <router-link to="/" class="nav-link" aria-current="page">
              Home
            </router-link>
          </li>
          <li class="nav-item align-self-lg-center">
            <router-link to="/posts" class="nav-link">
              Blog
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item d-none d-lg-block">
            <a href="https://mobile.twitter.com/giangzadev" class="nav-link" target="_blank" rel="noopener">
              <img src="@/assets/img/icons/twitter.svg" class="img-fluid img-logo" alt="Gian's Twitter">
            </a>
          </li>
          <li class="nav-item d-none d-lg-block">
            <a href="https://www.instagram.com/giancarlosgza/" class="nav-link" target="_blank" rel="noopener">
              <img src="@/assets/img/icons/instagram-line.svg" class="img-fluid img-logo" alt="Gian's Instagram">
            </a>
          </li>
          <li class="nav-item d-none d-lg-block">
            <a href="https://www.codepen.io/giancarlosgza/" class="nav-link" target="_blank" rel="noopener">
              <img src="@/assets/img/icons/codepen.svg" class="img-fluid img-logo" alt="Gian's Codepen">
            </a>
          </li>
          <li class="nav-item d-none d-lg-block">
            <a href="https://www.producthunt.com/@giancarlos_gza" class="nav-link" target="_blank" rel="noopener">
              <img src="@/assets/img/icons/product-hunt-line.svg" class="img-fluid img-logo" alt="Gian's Product Hunt">
            </a>
          </li>

          <template v-if="user.loggedIn && rank === 2">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="dropdownAdmin" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false">
                Admin Panel
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownAdmin">
                <li>
                  <router-link to="/admin/posts" class="dropdown-item">
                    <i class="material-icons-outlined me-2">settings</i>
                    Posts Panel
                  </router-link>
                </li>
              </ul>
            </li>
          </template>
          <template v-if="user.loggedIn && rank === 2">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="dropdownUser" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false">
                {{ user.data.displayName || 'Profile' }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownUser">
                <li>
                  <router-link to="/user/profile" class="dropdown-item">
                    <i class="material-icons-outlined me-2">person</i>
                    Profile
                  </router-link>
                </li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li>
                  <button class="dropdown-item dropdown-i-danger" @click.prevent="signOut">
                    <i class="material-icons-outlined me-2">logout</i>
                    Sign out
                  </button>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import {mapGetters} from 'vuex'

export default {
  name: 'Navbar',
  data() {
    return {
      showShadow: true,
      lastScrollPosition: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return
      }

      this.showShadow = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    },
    signOut() {
      firebase.auth()
          .signOut()
          .then(() => {
            this.$router.replace({
              name: "Home"
            });
          });
    },
  },
  computed: {
    ...mapGetters(['user', 'rank']),
    darkMode: {
      get() {
        return this.$store.getters.darkMode
      },
      set(newValue) {
        this.$store.commit("SET_DARK_MODE", newValue)
      }
    }
  },
}
</script>