<template>
  <div>
    <div>
      <label :for="id" class="mb-2">{{ label }}</label>
      <div :class="['input-file-dropbox', value ? 'input-file-dropbox-success' : '']">
        <input
            @input="handleInput"
            :id="id"

            class="form-control input-file"
            type="file"
        />

        <p v-if="value" class="overline pb-0">
          <i class="material-icons-outlined mdc-18">check</i>{{ value ? value.name : '' }}
        </p>
        <p v-else class="overline pb-0"><i class="material-icons-outlined mdc-18">add_circle</i>
          Upload an image
        </p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'label',
    'id',
    'value'
  ],
  data() {
    return {
      content: this.value
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.files[0])
    }
  }
}
</script>