import { render, staticRenderFns } from "./PostsPanel.vue?vue&type=template&id=1c2416be&"
import script from "./PostsPanel.vue?vue&type=script&lang=js&"
export * from "./PostsPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports