<template>
  <div>
    <Navbar></Navbar>
    <div class="container mt-3 mt-lg-5">

      <!-- GO BACK BUTTON -->
      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <router-link to="/posts" class="btn btn-link btn-micint-xr ripple ripple-primary m-0">
            <i class="material-icons-outlined">arrow_left</i>
            Go back
          </router-link>
        </div>

        <!-- POST HEADER IMAGE -->
        <div class="col-md-12 col-lg-8 mb-5">
          <img v-if="headerImage" :src="headerImage" class="img-fluid w-100 border-radius-25"
               :alt="post.title + ' image'" :title="post.title + ' - image'">
        </div>

        <!-- POST HEADER -->
        <div class="col-md-12 col-lg-8">
          <!-- POST TITLE -->
          <h1 class="text-hero fw-500 mb-3">{{ post.title }}</h1>
          <!-- POST MADE BY & DATE -->
          <div class="d-flex mb-3">
            <div class="align-self-center me-2">
              <img src="@/assets/img/gian.jpg" class="img-fluid border-radius-50 me-1" width="40px" height="40px"
                   alt="Giancarlos Gza">
            </div>
            <div>
              <div class="d-flex flex-column">
                <div class="subtitle-1">
                  <a href="https://mobile.twitter.com/giancarlosgza" class="text-underline" target="_blank"
                     rel="noopener">
                    by Giancarlos Garza</a>
                </div>
                <div class="overline text-muted">
                  {{ post.createdAt }}
                </div>
              </div>
            </div>
          </div>
          <!-- POST DESCRIPTION -->
          <p class="paragraph">{{ post.description }}</p>
        </div>
      </div>

      <!-- POST BODY-->
      <div class="row mb-3">
        <div class="col-md-12 col-lg-8">
          <div class="ql-container">
            <div class="ql-editor" v-html="post.body"></div>
          </div>
        </div>
      </div>

      <!-- POST FOOTER -->
      <div class="row mb-3">
        <div class="col-md-12 col-lg-8 mb-3">
          <hr>
          <div class="subtitle-1 fw-500 text-body mb-3">
            <img src="@/assets/img/gian.jpg" class="img-fluid border-radius-50 me-1" width="30px" height="30px"
                 alt="Giancarlos Gza">
            <a href="https://mobile.twitter.com/giancarlosgza" class="text-underline" target="_blank" rel="noopener">
              Giancarlos Garza</a>, Front end developer
          </div>
        </div>

        <!-- SHARE BUTTONS ON BOTTOM -->
        <div class="col-md-12 col-lg-8 mb-3">
          <a class="btn btn-default-outline btn-round ripple ripple-primary px-3"
             :href="'https://www.facebook.com/sharer/sharer.php?u=https://giangza.dev/posts/'+ this.$route.params.postId + '&quote=' + post.title"
             target="_blank"
             title="Share on Facebook">
            <img src="@/assets/img/icons/facebook-circle-fill.svg" class="img-fluid img-logo me-2" width="24px"
                 height="24px" alt="">
            Post
          </a>

          <a class="btn btn-default-outline btn-round ripple ripple-primary px-3"
             :href="'https://twitter.com/intent/tweet?&text=' + post.title +'&url=https://giangza.dev/posts/'+ this.$route.params.postId +'&via=giancarlosgza'"
             target="_blank" title="Share on Twitter">
            <img src="@/assets/img/icons/twitter-fill.svg" class="img-fluid img-logo me-2" width="24px"
                 height="24px" alt="">
            Tweet
          </a>
        </div>
      </div>

      <!-- POST SOCIAL SHARE BUTTONS FIXED LG -->
      <div class="social-share-container d-none d-lg-block">
        <div class="social-share-facebook mb-3">
          <a class="btn btn-default btn-round ripple ripple-primary w-50 px-3"
             :href="'https://www.facebook.com/sharer/sharer.php?u=https://giangza.dev/posts/'+ this.$route.params.postId + '&quote=' + post.title"
             target="_blank"
             title="Share on Facebook">
            <img src="@/assets/img/icons/facebook-circle-fill.svg" class="img-fluid img-logo me-2" width="24px"
                 height="24px" alt="">
            Post
          </a>
        </div>
        <div class="social-share-twitter mb-3">
          <a class="btn btn-default btn-round ripple ripple-primary w-50 px-3"
             :href="'https://twitter.com/intent/tweet?&text=' + post.title +'&url=https://giangza.dev/posts/'+ this.$route.params.postId +'&via=giancarlosgza'"
             target="_blank" title="Share on Twitter">
            <img src="@/assets/img/icons/twitter-fill.svg" class="img-fluid img-logo me-2" width="24px"
                 height="24px" alt="">
            Tweet
          </a>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import Navbar from "@/components/includes/Navbar";
import Footer from "@/components/includes/Footer";

export default {
  name: "Post",
  components: {Footer, Navbar},
  metaInfo() {
    return {
      title: this.post.title + ' | Post',
      meta: [
        {vmid: 'description', name: 'description', content: this.post.description},
        {vmid: 'keywords', name: 'keywords', content: this.post.postKeywords},
        {vmid: 'og:image', name: 'og:image', content: this.post.headerImage},
        {vmid: 'twitter:image', name: 'twitter:image', content: this.post.headerImage},
      ]
    }
  },
  data() {
    return {
      error: null,
      post: {},
    }
  },
  mounted() {
    this.getPost()
  },
  methods: {
    async getPost() {
      const db = firebase.firestore()
      const post = await db.collection('posts').doc(this.$route.params.postId).get()
      this.post = {id: post.id, ...post.data()}
      console.log(this.$route.params.postId)
    },
  },
  computed: {
    headerImage() {
      return this.post.headerImage ?? ''
    }
  }
}
</script>