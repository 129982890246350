<template>
  <div>
    <Navbar></Navbar>
    <div class="container mt-3 mt-lg-5">

      <!-- TITLE-->
      <div class="row align-items-center mb-3">
        <div class="col-md-6">
          <h6 class="overline text-primary fw-500">Panel</h6>
          <vue-aos animation-class="animate__animated animate__flipInX">
            <h4 class="fw-500">Posts</h4>
          </vue-aos>
        </div>
        <div class="col-md-6 text-md-end mb-3">
          <router-link to="/admin/posts/create"
                       class="btn btn-primary btn-fab-extended btn-micint-rotate border-radius-50 ripple m-0 px-3">
            <i class="material-icons-outlined me-md-1">add</i>
            <span class="btn-fab-text-label">Create post</span>
          </router-link>
        </div>
      </div>

      <!-- TABLE -->
      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <div v-if="error" class="alert alert-danger mb-3"><i class="material-icons-outlined me-2">error</i>{{ error }}
          </div>
          <div class="table-responsive">
            <table class="table table-borderless table-hover">
              <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col" class="w-100">Posts</th>
                <th scope="col">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(post, index) in posts" v-bind:key="index + 'post-list'">
                <th scope="col">{{ post.id }}</th>
                <td class="text-capitalize">{{ post.title }}</td>
                <td>
                  <router-link :to="'/post/' + post.id" class="btn btn-default btn-sm ripple ripple-dark"
                               target="_blank">
                    <i class="material-icons-outlined">visibility</i>
                  </router-link>
                  <router-link :to="'/admin/posts/edit/' + post.id"
                               class="btn btn-default btn-sm ripple ripple-dark" target="_blank">
                    <i class="material-icons-outlined">edit</i>
                  </router-link>
                  <button class="btn btn-default btn-sm ripple ripple-dark" type="button" data-bs-toggle="modal"
                          :data-bs-target="'#deleteConfirmation' + post.id">
                    <i class="material-icons-outlined">delete</i>
                  </button>
                  <ModalDeleteConfirmation :label="'Delete post'" @deleteItem="deletePost(post.id)"
                                           :id="'deleteConfirmation' + post.id"
                                           :name="post.title"></ModalDeleteConfirmation>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- PAGINATION SECTION -->
      <div class="row mb-3">
        <div class="col-md-12 text-center mb-3">
          <button class="btn btn-default btn-round ripple ripple-dark px-5" @click="loadMorePosts">Load more
            posts
            <i class="material-icons-outlined">expand_more</i>
          </button>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/includes/Navbar";
import Footer from "@/components/includes/Footer";
import ModalDeleteConfirmation from "@/components/admin/ModalDeleteConfirmation";
import VueAos from 'vue-aos'
import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  name: "PostsPanel",
  metaInfo: {
    title: 'Posts Panel',
  },
  components: {Footer, Navbar, VueAos, ModalDeleteConfirmation},
  data() {
    return {
      posts: [],
      db: firebase.firestore(),
      lastPost: null,
      error: null
    }
  },
  mounted() {
    this.fetchPosts()
  },
  methods: {
    async fetchPosts() {
      try {
        let postsSnap = this.db
            .collection('posts')

        postsSnap = await postsSnap
            .orderBy('createdAt', "desc")
            .limit(12)
            .get()

        this.lastPost = postsSnap.docs[postsSnap.docs.length - 1]
        this.posts = postsSnap.docs.map(doc => {
          return {id: doc.id, ...doc.data()}
        })

      } catch (e) {
        console.log(e.message)
      }
    },
    async loadMorePosts() {
      try {
        let postsSnap = this.db
            .collection('posts')

        postsSnap = await postsSnap
            .orderBy('createdAt', "desc")
            .limit(12)
            .startAfter(this.lastPost)
            .get()

        this.lastPost = postsSnap.docs[postsSnap.docs.length - 1]

        postsSnap.forEach(doc => {
          this.posts.push({id: doc.id, ...doc.data()})
        })

      } catch (e) {
        console.log(e.message)
      }
    },

    deletePost(id) {
      this.db.collection('posts').doc(id).delete().then(() => {
        this.posts = this.posts.filter(post => id !== post.id)
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'success',
          title: 'Post successfully deleted!',
        })
      }).catch(err => {
        this.error = err.message
      })
    }

  },
}
</script>