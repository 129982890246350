<template>
  <div>
    <div class="container h-100">
      <div class="row justify-content-center align-items-center h-100">
        <div class="col-md-12 text-center">
          <h1 class="text-glitch fw-500" data-glitch="404">404</h1>
          <h4 class="fw-500 mt-5">Oops! Something's missing.</h4>
          <h6>It seems you're in the wrong page D:</h6>
          <router-link to="/" class="btn btn-primary btn-round ripple px-5">Back home</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  metaInfo: {
    title: 'Page Not Found'
  },
}
</script>