import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import 'firebase/firestore'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            data: null,
        },
        darkMode: false,
        rank: null
    },
    getters: {
        user: state => state.user,
        darkMode: state => state.darkMode,
        rank: state => state.rank
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        SET_RANK(state, rank) {
            state.rank = rank
        },
        SET_DARK_MODE(state, darkMode) {
            state.darkMode = darkMode
        }
    },
    actions: {
        async fetchUser({commit}, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user) {
                let userRank = await firebase.firestore().collection('ranks').doc(user.uid).get()
                userRank = userRank.data()

                commit("SET_USER", {
                    email: user.email,
                    uid: user.uid,
                    photoURL: user.photoURL,
                    displayName: user.displayName,
                });
                if (userRank) {
                    commit("SET_RANK", userRank.rank)
                }
            } else {
                commit("SET_USER", null);
            }
        },
    },
    modules: {}
})
