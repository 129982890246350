<template>
  <div>
    <Navbar></Navbar>

    <div class="container mt-3 mt-lg-5">

      <!-- TITLE-->
      <section class="section-profile-banner"></section>
      <div class="profile-container align-items-center">
        <img v-if="user.data.photoURL" :src="user.data.photoURL" class="img-fluid profile-picture" width="100px"
             :alt="user.data.displayName + ' profile picture'">

        <div class="text-start">
          <h4 v-if="user.data.displayName" class="fw-bold">{{ user.data.displayName }}</h4>
          <h6 class="subtitle-1 text-muted">{{ user.data.email }}</h6>
        </div>
      </div>

      <!-- SHORTCUTS -->
      <div class="row mb-3">
        <div class="col-md-12 mb-2">
          <h6 class="overline fw-500 text-muted">Browse</h6>
        </div>

        <div class="col-md-4 mb-3">
          <router-link to="/admin/posts/create">
            <div class="card card-outline translate-up ripple ripple-primary">
              <div class="card-header mt-2">
                <i class="material-icons text-primary mdc-48">edit</i>
              </div>
              <div class="card-body">
                <h6 class="fw-bold">Create post</h6>
                <h6 class="subtitle-1 text-muted">Let's create a new post.</h6>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mb-3">
          <router-link to="/posts">
            <div class="card card-outline translate-up ripple ripple-primary">
              <div class="card-header mt-2">
                <i class="material-icons text-success mdc-48">dynamic_feed</i>
              </div>
              <div class="card-body">
                <h6 class="fw-bold">See active posts</h6>
                <h6 class="subtitle-1 text-muted">Go to the published posts view</h6>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mb-3">
          <router-link to="/admin/posts">
            <div class="card card-outline translate-up ripple ripple-primary">
              <div class="card-header mt-2">
                <i class="material-icons text-secondary mdc-48">dashboard</i>
              </div>
              <div class="card-body">
                <h6 class="fw-bold">Posts panel</h6>
                <h6 class="subtitle-1 text-muted">Go to posts admin panel</h6>
              </div>
            </div>
          </router-link>
        </div>

      </div>

      <!-- TABLE INFORMATION -->
      <div class="row mb-5">
        <div class="col-md-12 mb-2">
          <h6 class="overline fw-500 text-muted">My information</h6>
        </div>
        <div class="col-md-12 mb-3">
          <div class="card card-outline">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover table-borderless mb-0">
                  <tbody>
                  <tr>
                    <th scope="row">Name</th>
                    <td>{{ user.data.displayName }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td>{{ user.data.email }}</td>
                  </tr>
                  <tr>
                    <th scope="row">UID</th>
                    <td>{{ user.data.uid }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/includes/Footer";
import Navbar from "@/components/includes/Navbar";
import {mapGetters} from 'vuex'

export default {
  name: 'Profile',
  metaInfo() {
    return {
      title: this.user.data.displayName,
    }
  },
  components: {Navbar, Footer},
  computed: {
    ...mapGetters(['user'])
  },
}
</script>