<template>
  <footer>
    <div class="container py-3">
      <div class="row mb-3">

        <div class="col-md-4 text-center text-md-start mb-5 mb-md-3">
          <a href="https://www.gitkraken.com/invite/gqVVLWVF" target="_blank" rel="noopener"
             title="GitKraken referral code">
            <img src="@/assets/img/logos/products/gitkraken-ambassador-logo.png" width="100px" height="100px"
                 class="img-fluid mb-3"
                 alt="giangzadev logo">
          </a>
          <div>
            <input type="checkbox" class="btn-check" id="btn-check" autocomplete="off" v-model="darkMode">
            <label class="btn btn-primary-outline btn-micint-rotate btn-round ripple ripple-primary m-0"
                   for="btn-check">
              <i class="material-icons-outlined me-2" :class="[ darkMode ? 'text-warning' : 'text-muted']">
                {{ darkMode ? 'brightness_5' : 'dark_mode' }}</i>
              Turn {{ darkMode ? 'on' : 'off' }} the lights
            </label>
          </div>

        </div>

        <div class="col-6 col-md-4 mb-3 mb-md-0">
          <h6 class="fw-bold mb-3">Projects</h6>
          <div class="mb-3">
            <a href="https://the-docs.com/?ref=colorffy" title="Menuffy" rel="noopener" target="_blank"
               class="subtitle-1 text-underline">
              TheDocs
            </a>
          </div>
          <div class="mb-3">
            <a href="https://menuffy.com/?ref=giangza" title="Menuffy" rel="noopener" target="_blank"
               class="subtitle-1 text-underline">
              Menuffy
            </a>
          </div>
          <div class="mb-3">
            <a href="https://colorffy.com/?ref=giangza" title="Colorffy" rel="noopener" target="_blank"
               class="subtitle-1 text-underline">
              Colorffy
            </a>
          </div>
        </div>

        <div class="col-6 col-md-4 mb-3 mb-md-0">
          <h6 class="fw-bold mb-3">Follow me</h6>
          <div class="mb-3">
            <a href="https://twitter.com/giancarlosgza" title="Twitter" target="_blank"
               rel="noopener" class="subtitle-1 text-underline">
              <img class="img-fluid img-logo d-none d-md-inline-block me-2"
                   src="@/assets/img/icons/twitter-fill.svg"
                   alt="Twitter">
              Twitter
            </a>
          </div>
          <div class="mb-3">
            <a href="https://www.instagram.com/giancarlosgza/" title="Twitter" target="_blank"
               rel="noopener" class="subtitle-1 text-underline">
              <img class="img-fluid img-logo d-none d-md-inline-block me-2"
                   src="@/assets/img/icons/instagram-line.svg"
                   alt="Instagram">
              Instagram
            </a>
          </div>
          <div class="mb-3">
            <a href="https://www.codepen.io/giancarlosgza/" title="Gian's CodePen" target="_blank"
               rel="noopener" class="subtitle-1 text-underline">
              <img class="img-fluid img-logo d-none d-md-inline-block me-2"
                   src="@/assets/img/icons/codepen.svg"
                   alt="CodePen">
              CodePen
            </a>
          </div>
          <div class="mb-3">
            <a href="https://www.producthunt.com/@giancarlos_gza" title="Gian's Product Hunt" target="_blank"
               rel="noopener" class="subtitle-1 text-underline">
              <img class="img-fluid img-logo d-none d-md-inline-block me-2"
                   src="@/assets/img/icons/product-hunt-line.svg"
                   alt="Instagram">
              Product Hunt
            </a>
          </div>
        </div>

      </div>

      <div class="row align-items-center mb-3">
        <div class="col-6 col-md-6 mb-3">
          <!-- BUY ME A COFFEE BADGE -->
          <a href="https://www.buymeacoffee.com/giancarlosgza" target="_blank" rel="noopener">
            <img class="border-radius-0" width="180px" height="54px" alt="Buy me a Coffee - Giancarlos Gza"
                 src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=giancarlosgza&button_colour=3b2eff&font_colour=ffffff&font_family=Poppins&outline_colour=ffffff&coffee_colour=FFDD00">
          </a>
        </div>
      </div>

      <hr class="my-3">

      <div class="row">
        <div class="col-8 col-md-6 text-start align-self-center">
          <h6 class="subtitle-1">Made with ❤️ & ☕ by
            <a href="https://mobile.twitter.com/giangzadev" title="Twitter"
               target="_blank" rel="noopener"
               class="subtitle-1 text-underline">@giancarlosgza</a>
          </h6>

          <div class="float-made-by">
            <small>By: </small>
            <a href="https://mobile.twitter.com/giangzadev" target="_blank" rel="noopener">
              <img src="@/assets/img/gian-indigo.png" width="20px"
                   height="20px"
                   alt="Gian photo" class="img-fluid border-radius-50"> @giangzadev</a>
          </div>
        </div>

        <div class="col-4 col-md-6 text-end">
          <h6 class="subtitle-1">
            ©2021 giangza.dev
            <i class="material-icons">code</i>
          </h6>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    darkMode: {
      get() {
        return this.$store.getters.darkMode
      },
      set(newValue) {
        this.$store.commit("SET_DARK_MODE", newValue)
      }
    }
  }
}
</script>
